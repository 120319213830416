<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span class="brand-logo">
                <b-img
                  :src="appLogoImage"
                  alt="logo"
                />
              </span>
              <b-img class="brand-text" :src="appLogoText" height="45px"/>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle pb-1" >
            <b-link class="nav-link modern-nav-toggle pb-1" >
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="menuItens"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import { mapState } from 'vuex'
import { getUserData } from '@/auth/utils'
import menu from '@/navigation/vertical'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      menuItens: [],
      appLogoText: $themeConfig.app.appLogoText,
    }
  },
  computed: {
    ...mapState(['auth/user']),
    ...mapState(['appConfig/layout']),
  },

  created() {
    const theme = window.localStorage.getItem('vuexy-skin') || 'light'
    this.appLogoText = theme === 'dark' ? $themeConfig.app.appLogoTextDark : $themeConfig.app.appLogoText
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'auth/SET_AUTH_USER') {
        this.user = state.auth.user
        this.buildMenu()
      }
      if (mutation.type === 'appConfig/UPDATE_SKIN') {
        this.appLogoText = mutation.payload === 'dark' ? $themeConfig.app.appLogoTextDark : $themeConfig.app.appLogoText
      }
    })
    this.buildMenu()
  },
  methods: {
    buildMenu() {
      const navMenu = []
      const user = getUserData()
      if (!user) {
        this.$router.push({ name: 'login' })
      }
      const buildMenuTree = item => {
        const currentItem = { ...item }
        let hasSubmenus = item.children
        if (item.children) {
          currentItem.children = []
          const submenus = []

          // eslint-disable-next-line no-restricted-syntax
          for (const menuItem of item.children) {
            if (menuItem.profile.some(profile => profile === user.role)) {
              submenus.push(menuItem)
            }
          }

          hasSubmenus = submenus.length > 0
          if (hasSubmenus) {
            currentItem.children = submenus
          }
        }

        if ((!item.route && hasSubmenus) || currentItem.profile.some(profile => profile === user.role)) {
          navMenu.push(currentItem)
        }
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const item of menu) {
        buildMenuTree(item)
      }
      this.menuItens = navMenu
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'MenuIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage, appLogoText } = $themeConfig.app

    return {
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
