<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
      <promotion-nav-bar-header
        :promotion="promotion"
        :prize-draw="prize_draw"
      />
      <b-navbar-nav class="nav align-items-center ml-auto">
        <b-nav-item-dropdown
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
        >
          <template #button-content>
            <div class="d-sm-flex d-none user-nav">
              <p class="user-name font-weight-bolder mb-0">
                {{ user.name }}
              </p>
              <span class="user-status">{{ user.client.name || '' }}</span>
            </div>
            <b-avatar
              size="40"
              variant="light-primary"
              class="badge-minimal"
              badge-variant="success"
              :src="user.avatar"
            />
          </template>

          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="account"
          >
            <feather-icon
              size="16"
              icon="UserIcon"
              class="mr-50"
            />
            <span>Minha Conta</span>
          </b-dropdown-item>
          <b-dropdown-divider />

          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="logout"
          >
            <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
            />
            <span>Sair</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </div>
  </div></template>

<script>
import {
  BAvatar, BDropdownDivider, BDropdownItem, BLink, BNavbarNav, BNavItemDropdown,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { getUserData } from '@/auth/utils'
import { mapState } from 'vuex'
import PromotionNavBarHeader from '@/components/app/promotion/PromotionNavBarHeader.vue'

export default {
  components: {
    PromotionNavBarHeader,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      user: {
        client: {
          name: '',
        },
      },
      promotion: {
        name: 'Nenhuma Selecionada',
      },
      prize_draw: {
        name: '',
      },
    }
  },
  computed: {
    ...mapState(['auth/user']),
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'auth/SET_AUTH_USER') {
        this.user = state.auth.user

        if (this.user.current_promotion) {
          this.promotion = this.user.current_promotion
        } else {
          this.promotion = {
            name: 'Nenhuma Selecionada',
          }
        }
        if (this.user.current_prize_draw) {
          this.prize_draw = this.user.current_prize_draw
        } else {
          this.prize_draw = {
            name: '',
          }
        }
      }
    })
  },
  beforeDestroy() {
    this.unsubscribe()
  },
  mounted() {
    this.user = getUserData()
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      // localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      // localStorage.removeItem('userData')
      localStorage.clear()

      // Redirect to login page
      this.$router.push({ name: 'login' })
    },
    account() {
      this.$router.push({ name: 'my-account' })
    },
  },
}
</script>
