<template>
  <b-row
    class="pl-4 d-sm-flex nav-item d-sm-block"
    style="width: 100%;display: block; text-align: center"
  >
    <b-col
      class="text-right"
      rowspan="2"
      md="5"
    >
      <h3>{{ current_promotion.name }}</h3>
    </b-col>
    <b-col class="text-left prize_draw_text_color_info">
      {{ drawName }}
      <br>
      {{ drawDates }}
    </b-col>
  </b-row>
</template>

<script>
import moment from 'moment'

export default {
  name: 'PromotionNavBarHeader',
  props: {
    promotion: {
      type: Object,
      require: true,
      default() {
        return {
          name: 'Selecione uma promoção',
        }
      },
    },
    prizeDraw: {
      type: Object,
      require: true,
      default() {
        return {
          name: '-',
        }
      },
    },
  },
  data() {
    return {
      drawDate: '',
      current_promotion: { name: '' },
      current_prize_draws: {},
    }
  },
  computed: {
    drawName() {
      let { name } = this.current_prize_draws
      if (this.current_prize_draws.draw_date) {
        name += ` em ${this.current_prize_draws.draw_date}`
      }
      return name
    },
    drawDates() {
      let dates = ''
      if (this.current_prize_draws.uuid) {
        dates += `distribuição de ${this.current_prize_draws.start_date} até ${this.current_prize_draws.end_date}`
      }
      return dates
    },
  },
  watch: {
    prizeDraw(value) {
      this.current_prize_draws = value
    },
    promotion(value) {
      this.current_promotion = value
    },
  },
}
</script>

<style scoped>
.prize_draw_text_color_info {
  color: var(--prize_draw_info_text)
}
</style>
